@import '../../../Styles/settings';

.datePicker {
  flex-direction: column;
  width: 100%;
}

.rightContainer {
  --padding: 16px;

  @media #{$md-up} {
    --padding: 0 0 48px 48px;
  }

  padding: var(--padding);

  input,
  textarea {
    border: 1px solid $near-black;
  }

  textarea {
    min-height: 128px;
  }

  @media #{$md-up} {
    textarea[data-ba='goodsMark'] + label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
  }
}

.leftContainer {
  --padding: 16px;

  @media #{$md-up} {
    --padding: 0 48px 48px 0;
  }

  padding: var(--padding);
}

.shipping-info {
  --rich-text-size: 14px;

  @media #{$md-up} {
    --rich-text-size: 16px;
  }

  margin-bottom: 24px;

  &__richText * {
    font-size: var(--rich-text-size);
  }
}

.paymentTerms {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 8px;
}

.loadingDotsWrapper {
  width: 100%;
  text-align: center;
  height: 40px;
}

.slideDownOuter {
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  --margin-top: 16px;
  --padding: 16px;

  @media #{$md-up} {
    --margin-top: 0;
    --padding: 0 48px 48px 48px;
  }

  display: block;
  margin-top: var(--margin-top);
  // padding: var(--padding);
}

.slideDownExpanded {
  height: auto;
}

.orderInformationTop {
  --top-padding: 24px 16px;
  --border: 1px solid $light-gray;

  @media #{$md-up} {
    --top-padding: 48px;
    --border: none;
  }

  padding: var(--top-padding);
  border-bottom: var(--border);
}

@import '../../../Styles/settings';

.root {
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
}

.input {
  margin-bottom: 6px;
  padding-top: 15px;

  input {
    border: 1px solid $near-black;
  }
}

.input,
.note {
  width: 100%;
}

.note {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  flex: 0 0 100%;
  font-size: 12px;
}

.date {
  font-size: 12px;
  font-weight: 300;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
}

@include media(600px) {
  .root {
    display: flex;
    align-items: center;
  }

  .note,
  .input {
    margin-bottom: 0;
  }

  .input {
    width: 300px;
  }

  .note {
    max-width: calc(50% + 47px);
    margin-bottom: 6px;
  }

  .date {
    margin-left: auto;
    margin-right: 0;
  }
}

@include media(700px) {
  .rootLessPaddingTop {
    padding-top: 10px;
  }
}

@include media(800px) {
  .input {
    width: 450px;
  }

  .input,
  .note {
    margin-bottom: 0;
  }

  .note {
    max-width: calc(100% - 250px);
  }
}

@media #{$md-up} {
  .note,
  .date {
    font-size: 14px;
  }

  .input {
    width: calc(((100% - 198px) + 52px) / 2);
  }

  .note {
    max-width: calc(100% - 300px);
  }
}

@import '../../../Styles/settings';

.price-list {
  --item-spacing: 8px;
  --container-margin: 32px;

  @media #{$md-up} {
    --item-spacing: 16px;
    --container-margin: 0;
  }

  margin-top: var(--container-margin);

  &__item {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    margin-bottom: var(--item-spacing);
    line-height: 20px;
    color: $darkest-gray;

    &:last-of-type {
      margin: 0;
    }

    &--big {
      font-size: 18px;
      line-height: 24px;
      @include HelveticaMedium;
      color: $near-black;
    }
  }

  &__itemBlue {
    @extend .price-list__item;
    color: $light-blue;
  }
}

.rootStyles {
  padding: 0;

  & > div {
    padding: 0;

    & > div {
      padding: 0 0 20px;
    }
  }
}
@import '../../../Styles/settings';

.select {
  padding: 0px 16px;
  font-size: 16px;
  height: 48px;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6L8 10L12 6' stroke='%2300205C' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: calc(100% - 16px);
  -webkit-appearance: none;
  border: 1px solid $near-black;
  border-radius: 4px;

  &:focus-within,
  &:focus-visible {
    border: 1px solid $light-blue;
    outline: 0;
  }

  option {
  }
}

.floatLabel:focus-within label {
  color: $light-blue;
}

.floatLabel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
}

.floatLabel label {
  font-size: 16px;
  margin-left: 12px;
  color: $dark-gray;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 12px) scale(0);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  background-color: white;
  visibility: hidden;
}

.floatLabel:focus-within label {
  transform: translate(16px, -8px) scale(0.75);
  margin-left: 0px;
  padding: 0 4px;
  visibility: initial;
}

.errorMessage {
  font-size: 10px;
  position: relative;
  top: -6px;
  left: 10px;
  background: white;
  padding: 0 5px;
  line-height: 10px;
}

.error {
  @include input-has-error;
  &:focus {
    outline-style: auto;
    outline: 1px solid $color-error;
  }
}

.disabled {
  background-color: #e1e0e0;
  border: #e1e0e0 1px solid;
}

.bold {
  font-weight: 500;
}

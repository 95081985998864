@import '../../Styles/settings';

.root {
  background: $near-white;
  padding: 24px 16px 64px 16px;
  min-height: 50vh;
}

.header {
  display: flex;
  align-items: baseline;
}

.h1 {
  font-weight: 300;
  font-size: 25px;
  padding-right: 5px;
  margin-bottom: 0;
  line-height: 35px;
}

.cartSection {
  max-width: 960px;
  margin: 0 auto 35px;
  background-color: white;
  margin-bottom: 32px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
}

.checkoutBtn {
  font-size: 14px;
  background-color: $light-blue;
  color: white;
  border: 0;
  padding: 16px;
  font-weight: bold;
  cursor: pointer;
  min-width: 123px;
  border-radius: 4px;
}

.backBtn {
  font-size: 14px;
  border: 0;
  padding: 16px;
  font-weight: bold;
  background-color: $white;
  color: $midnight-blue;
  cursor: pointer;
  min-width: 97px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
}

.hiddenBtn {
  visibility: hidden;
  height: 0;
}

.checkoutBtnContainer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background-color: $white;
  width: 100%;
  z-index: 1000;
}

.buttonsContainer {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
}

.checkoutBtnColor {
  background-color: $light-blue;

  &:hover {
    background-color: $hover-blue;
  }
}

.continueBtnColor {
  background-color: $midnight-blue;

  &:hover {
    background-color: $darkmidnight_blue;
  }
}

.spiner {
  width: 16px;
  height: 16px;
}

@media #{$md-up} {
  .root {
    padding: 70px 0;
  }

  .buttonsContainer {
    padding: 0 48px;
  }

  .checkoutBtn {
    min-width: 312px;
  }
}

@import '../../../Styles/settings';

.address {
}

.subHeadingWrapper {
  flex: 0 0 100%;
  padding-top: 6px;
}

.newAddressContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  position: relative;
  column-gap: 16px;
  background-size: 7px 1px;
}

.container {
  flex: 0 0 50%;
}

.lastInputContainer {
  margin-bottom: -24px;
}

@include media(600px) {
  .subHeadingWrapper {
    padding: 6px 15px 0;
  }

  .addressContainer {
    margin-bottom: -24px;
  }

  .icon {
    display: block;
    width: 28px;
    height: 35px;
    margin-right: 15px;
  }
}
